.Jackpot{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

}
.Jackpot h1{
    padding: 10px;
    display: inline-block;
    background-color: #303036;
    color: white;
    transform: translateY(-0.75em);
}
.JackpotWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.ImageAndJackpot img {
    width: 350px;
    height: 250px; 
    max-width: 100%;
}


@media(max-width:551px){
    .ImageAndJackpot img {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 551px) and (max-width: 1051px) {
    .ImageAndJackpot img {
        width: 200px;
        height: 200px;
    }
}

