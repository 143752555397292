.OpenHours {
    text-align: center;
    font-family:sans-serif; 
  }
  
  .OpenHours table {
    margin: auto;
    width: 50%;
    padding:3em;
    margin:1em auto;
  }
  
  .OpenHours th {
    background-color: #303036;
    color:white;
    
  }

  .OpenHours th,
  .OpenHours td {
    padding: 8px;
    text-align: center;

  }
  
  .Day {
    background-color: #f5f5f5;
  }
  
  .Hour {
    background-color: #eae0d5;
  }
  
  
  
  @media(max-width: 651px) {
    .OpenHours table {
      width: 100%;
      padding:2.5em;
    }
  }
  