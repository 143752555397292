.ServiceGrid{
    text-align: center; 
}

.ServiceGrid h1 {
    padding: 10px;
    background-color: #303036;
    color: white;
}
  
.ServiceGridContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    text-align: center;
    height:auto; 
}

.Service{
    background: #EDEDED;
    padding:0.5em;
    margin: 2em auto;
    width:75%;
}
  
.Service h2 {
    background-color: #303036;
    color: white;
    text-align: center;
    height: fit-content;
    margin:0.75em auto;
    padding:0.15em;
}

.ServiceTextWrapper{
    margin:auto;
    font-family: sans-serif;
}

.ServiceTextWrapper table{
    margin:0 auto;
}

.ServiceTextWrapper th{
  border-bottom:2px solid#000;
}

.Service td{
    padding:0.5em;
    font-weight:bold;
}

.Service img{
    height:300px;
    width:300px;
    order:2;
    
}

  @media(max-width: 811px){
  .ServiceGridContainer{
      display: inline;
      text-align: center;
      max-width:100%;
      width:100%;
  }

  .ServiceTextWrapper{
      width:200px;
  }

  .Service img{
      height:200px;
      width:200px;
  }

}
