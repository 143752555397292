.Footer{
    padding:1em;
    font-size: 1.25em;
    font-family:sans-serif;
    background:#303036;
    display:flex;
    justify-content: space-between;
}

.ContactInformation{
  padding:0.25em;
  color:white;
}

.ContactLink{
  text-decoration: none;
  font-style: normal;
  color:white;
}

.MapContainer{
  width:50%;
  
}

@media(max-width:651px){
  .Footer{
    display:flex;
    flex-direction: column;
    padding:0;
  }

  .MapContainer{
    width:100%;
    
  }

}