.Promotions{
    text-align: center;
}

.Promotions h1{
    padding: 10px;
    background-color: #303036;
    color: white;
    margin:0;
}

.ProductsWrapper{
    display: flex;
    justify-content: space-evenly;
    margin:2em auto;
    font-family: sans-serif;
    padding:1em;
}

.Product{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); 
}

.Product img{
    width:100%;
    height: 250px;
    display: block;
}

.TextWrapper{
    text-align: center;
    background-color: #EDEDED;
    color:black;
    padding:0.25em;
}

@media(max-width:751px){
    .ProductsWrapper{
        display: flex;
        flex-direction: column;
        padding:2em;
        margin:0;
    }

    .Product{
        margin:2em 0;
    }
    .Product img{
        height: auto;
    }

    
}