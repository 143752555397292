.Tiles {
  text-align: center;
  position: relative;
}

.Tiles h1 {
  background-color: #303036;
  color: white;
  margin: 0;
  padding: 10px;
}

.TilesWrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 1.99em;
  
}

.Tile {
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em auto;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  background-color: #EDEDED;
  z-index: 2;
}

.Tile img {
  width: 1000px; 
  height: 400px; 
  z-index: -1;
}

.TileTextWrapper {
  width:1000px;
  color: black;
  z-index: 1;
  font-family: sans-serif;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #303036;
  color: white;
  border: none;
  padding: 1em;
  cursor: pointer;
  z-index: 2;
}

.arrow.left {
  left: 0;
}

.arrow.right {
  right: 0;
}


@media (max-width: 1051px) {

  .Tile{
    max-width: 100%;
  }

  .Tile img{
    max-width: 100%;
    height:300px;
  }

  .TileTextWrapper{
    max-width: 100%;
    height: fit-content;
  }

  .arrow{
    padding:0.75em;
  }

}
