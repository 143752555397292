.nav{
    justify-content: flex-end;
}

nav ul{
    list-style:none;
    display: flex;
    gap:25px;
}

nav a{
    text-decoration: none;
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.NavBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    background: #303036;
    position: relative;
}

.NavBar.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.LogoNavBar{
    display: flex;
    align-items: center;
}

.LogoNavBar img{
    max-width: 100%;
    width:100px;
    height:100px;
    margin-right: 1em;
}


.LogoTextContainer{
    display: none;
}

.LogoTextContainer p{
    margin: 0;
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.HorizontalNavBar{
    padding:1em;
}

.HorizontalNavBar a.active {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
    font-style: italic;
}

  
  @media (max-width: 851px) {
  

    .NavBarMenu{
        display: none;
        flex-direction: column;
        position: absolute;
        top: 99.5%;
        right: 0;
        background-color:#303036;
        z-index: 1;
        opacity: 1;
    }
    
    .NavBarMenu.active{
        transform: translateX(0);
        display: flex;
        
    }
    
    .NavBarItems{
        flex-direction: column;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .NavBarItem{
        padding: 10px 20px;
        cursor: pointer; 
    }
    
    .NavBarToggle{
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding:1em;
    }
    
    .HamburgerIcon{
        width: 25px;
        height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .LogoTextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .Line{
        width: 25px;
        height: 3px;
        background-color: white;
        transform: translateY(-0.15em);
    }
    
    .HamburgerIcon.open .Line:nth-child(1) {
        transform: rotate(45deg) translate(5px, 6px);
      }
      
      .HamburgerIcon.open .Line:nth-child(2) {
        opacity: 0;
      }
      
      .HamburgerIcon.open .Line:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -6px);
      }
  }