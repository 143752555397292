.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 0;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;

  }
  
  .carousel-slide {
    min-width: 100%;
    height: 87vh;
  }
  
 .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #303036;
    color: white;
    border: none;
    padding: 1em;
    cursor: pointer;
    z-index: 2;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  }
  
  .arrow.prev {
    left: 0;
  }
  
  .arrow.next {
    right: 0;
  }
  
  @media(max-width:1051px){

    .carousel-slide{
      height:40vh;
    }

    .arrow{
      padding:0.75em;
    }
  }